<template>
  <div class="service-page">
    <div class="container">
      <div class="section-service">
        <div class="col-title">
          <div class="block-bg">
            <h3>SERVICIOS</h3>
          </div>
        </div>
        <div class="col-image">
          <img src="@/assets/service-banner.jpg" alt="">
        </div>
      </div>
      <div class="section-list service-covid">
        <h4>Nuestros Servicios</h4>
        <div class="block-list">
          <div class="service-item" style="border:0px !important;padding: 0px;">
            <h4 class="service-item--label d-none">Alimentación</h4>
            <ul class="nav service-item--list">
              <li style="margin:20px 0px"><i class="fa-solid fa-clock"></i> RECEPCION 24 HR.</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-map-location-dot"></i> EXCELENTE UBICACIÓN</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-wifi"></i> WIFI GRATIS EN TODO EL HOTEL</li>
            </ul>
          </div>
        </div>
        <div class="block-list">
          <div class="service-item" style="border:0px !important;padding: 0px;">
            <h4 class="service-item--label d-none">HABITACIONES</h4>
            <ul class="nav service-item--list">
              <li style="margin:20px 0px"><i class="fa-solid fa-vault"></i>   CAJA DE SEGURIDAD EN HABITACIONES</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-suitcase-rolling"></i> GUARDA EQUIPAJE</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-bed"></i>  ROOM SERVICE</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-utensils"></i> RESTAURANTE</li>
            </ul>
          </div>
        </div>
        <div class="block-list">
          <div class="service-item" style="border:0px !important;padding: 0px;">
            <h4 class="service-item--label d-none">EXTRAS</h4>
            <ul class="nav service-item--list">
              <li style="margin:20px 0px"><i class="fa-solid fa-square-parking"></i> ESTACIONAMIENTO</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-taxi"></i> SERVICIO DE TRASLADO (CON COSTO)</li>
              <li style="margin:20px 0px"><i class="fa-solid fa-jug-detergent"></i> SERVICIO DE LAVANDERIA Y PLANCHADO (CON COSTO)</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section-list service-covid">
        <h4>PAQUETE - ESCAPADA ROMANTICA</h4>
        <div class="block-list">
          <div class="service-item" style="border:0px !important;padding: 0px;">
            <div class="image-backgroud">
              <img src="@/assets/rooms/simple/room1.jpg" alt="">
            </div>
            <h4 class="service-item--label" style="padding: 10px;margin-bottom: 10px;">Básico</h4>
            <ul class="nav service-item--list" style="padding: 10px;">
              <li>Noche de alojamiento en habitación matrimonial</li>
              <li>Desayuno Buffet</li>
              <li>Estacionamiento*</li>
              <li>Descuento en EQUO Restaurant</li>
              <li>*Previa coordinación, sujeto a disponibilidad</li>
              <li>**Paquete en base para 2 personas</li>
            </ul>
          </div>
        </div>
        <div class="block-list">
          <div class="service-item" style="border:0px !important;padding: 0px;">
            <div class="image-backgroud">
              <img src="@/assets/rooms/suite/room1.jpg" alt="">
            </div>
            <h4 class="service-item--label" style="padding: 10px;margin-bottom: 10px;">Full</h4>
            <ul class="nav service-item--list" style="padding: 10px;">
              <li>Noche de alojamiento en Suite</li>
              <li>Desayuno Buffet</li>
              <li>2 bebidas</li>
              <li>Ronda de Piqueo</li>
              <li>Estacionamiento*</li>
              <li>Free late check out*</li>
              <li>Descuento en EQUO Restaurant</li>
              <li>*Previa coordinación, sujeto a disponibilidad</li>
              <li>**Paquete en base para 2 personas</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="service-covid">
        <h4>Algunos servicios del hotel pueden estar restringidos debido a regulaciones gubernamentales</h4>
        <p>Actualmente, las áreas comunes restringidas por el Ministerio de Comercio Exterior y turismo son: bar, business center, spa, sauna y sala de reuniones. Estas restricciones están sujetas a variación. El gobierno peruano se encuentra aplicando restricciones en base a citerios de territorialidad y servicios. Adicionalmente, tomar en cuenta que el servicio de desayuno en el restaurante del hotel se viene ofreciendo bajo la modalidad de desayuno a la carta.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ServicePage',
  data: () => ({
    loading: false,
  })
}
</script>
